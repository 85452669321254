import { defineStore }  from 'pinia'
import { useConfigStore } from './config.js'
import axios from 'axios'
import { getAuthToken } from '@/helpers/auth.js'
import { socket } from './../socket.js'
import { event } from 'vue-gtag'


//

export const useSurfStore = defineStore('surf', {
	state: () => ({
		queryControls: undefined,
		currYearToSortWith: undefined,
		isTesting: false,
		config: useConfigStore(),
		surfData: undefined,
		queryResults: undefined,
		fetching: false,
		noQueryResults: false,
		fetchingSurfData: false,
		defaultIdFL: '28-Mango-St_Clearwater_Pinellas_FL',
		defaultIdPR: 'Carretera-Principal-de-Culebra_Culebra_PR_Culebra_Municipality_10',
		expectedLoss: {
			value: undefined,
			max: undefined,
		},
		floodZoneExpectedLoss: {
			value: undefined,
		},
		tilesetAAL: undefined,
		averageAnnualLoss: {
			value: undefined,
			max: undefined,
		},
		equity: undefined,
		maxPropertiesAtRisk: undefined,
		meanNFIP: undefined,
		meanNFIPstr: undefined,
		houseLiftingLoan: {
			yearlyCost: undefined,
		},
		homeInsInfo: {
			rates: undefined,
			type: 'noWindMit_pre2001_150000',
			links: undefined,
		},
		propertyNotFound: { 
			isVisible: false,
			value: undefined,
			lngLat: undefined
		},
		rocketflood: {
			applicationID: undefined,
			location: undefined,
			quotes: undefined,
		},
	}),
	getters: {
		getSurfData: (state) => state.surfData,
		fullAddress: (state) => {
			
			if (state.surfData && state.surfData['location']['address']) {
				const street = (state.location.address.street != 'Unknown-Street' && state.location.address.street != 'Unknown-Road' && state.location.address.street != 'Tbd') ? state.location.address.street + '\n' : ''

				let isMunicipality = ''
				try {
					isMunicipality = (state.$route.name == 'puertoRico' && state.location.address.city == 'No City') ? ' ' + state.config.textContent['property_view']['municipality']['label'] : ''
				} catch {}

				const city = (state.location.address.city != 'No City') ? state.location.address.city + ', ' : state.location.address.county + isMunicipality + ', ' 

				return `${street}${city}${state.location.address.state} ${state.location.address.zip.replace(".0", "")}`	
			} else {
				return ''
			}
		},
		location: (state) => state.surfData ? state.surfData.location : '',
		blockgroup: (state) => state.surfData ? state.surfData.location.blockgroup : undefined,
		coordinates: (state) => {
			if (state.surfData) {
				if (state.surfData.location.coordinates.lng > 0) {
					return {  
						'lng': state.surfData.location.coordinates.lat,
						'lat': state.surfData.location.coordinates.lng,		
					}
				} else {
					return {
						'lng': state.surfData.location.coordinates.lng,
						'lat': state.surfData.location.coordinates.lat
					}
				}
			} else {
				return undefined
			}
		},
		FFE: (state) => (state.surfData && state.surfData.attributes && state.surfData.attributes.firstFloorElevation) ? state.surfData.attributes.firstFloorElevation.mean : 0,
		EL: (state) => state.expectedLoss.value,
		FZEL: (state) => state.floodZoneExpectedLoss.value,
		maxEL: (state) => state.expectedLoss.max,
		AAL: (state) => state.averageAnnualLoss.value,
		maxAAL: (state) => state.averageAnnualLoss.max,
		attributes: (state) => state.surfData ? state.surfData.attributes : undefined,
		floodZone: (state) => state.surfData ? state.surfData.attributes.floodZone : undefined,
		buildingArea: (state) => (state.surfData && state.surfData.attributes) ? state.surfData.attributes.area : undefined
	},
	actions: {
		setHomeInsType(newType) {
			this.homeInsInfo.type = newType
		},
		floodRisk(controls,textContent) {
			if (!this.fetchingSurfData){
				if (this.AAL) {
					//console.log(this.AAL,controls.dataSource,controls.year)
					var currentFloodRisk = this.AAL[controls.dataSource][controls.year]['mean']
					if (currentFloodRisk<1000) {
						return textContent['property_view']['low-flood-risk-desc']['label'] +  " $1,000 /" + textContent['property_view']['flood-risk-unit']['label']
					} else{
						return '$' + this.abbreviateNumbers(this.AAL[controls.dataSource][controls.year]['mean'], 2, false) + " /" + textContent['property_view']['flood-risk-unit']['label']
					}		
				} else {
					if (this.floodZone){
						if (this.floodZone.startsWith('A') || this.floodZone.startsWith('V')){
							return '$' + this.abbreviateNumbers(this.floodZoneExpectedLoss['value'], 3, true) + " " + textContent['property_view']['flood-risk-flood-zone-unit']['label']
						} else {
							return textContent['property_view']['flood-risk-flood-zone-low-risk']['label'] + " " + textContent['property_view']['flood-risk-flood-zone-unit']['label']
						}

					} else {
						return ''
					}
				}
			} else {
				return 'Loading...'
			}
		},
		setLngLat(lng, lat) {

			if (this.surfData && this.surfData['location']) {
				this.surfData['location']['coordinates'] = { 'lng': Number(lng), 'lat': Number(lat) }
			} else {
				this.surfData = {
					'location': {
						'coordinates': { 'lng': Number(lng), 'lat': Number(lat) }
					}
				}
			}
		},
		setTestingEnvironment: function() {
			this.isTesting = true
			this.rocketflood = {
				'applicationID': 'RFAA129847',
				'location': '952-39th-Ct_West-Palm-Beach_FL',
				'quotes': [
					{ "premium": 294 },
					{ "premium": 348 },
					{ "premium": 382 },
					{ "premium": 392 },
					{ "premium": 405 },
					{ "premium": 420 },
					{ "premium": 420 },
					{ "premium": 465 },
					{ "premium": 525 },
					{ "premium": 1007 },
					{ "premium": 1007 }
				]
			}
		},
		reportBug: async function(
			message = '', 
			email = this.config.user ? this.config.user.email : '',
			isMissingAddress = false) 
		{
			var data = new FormData()
			data.append('email', email)
			data.append('currentURL', this.$router.currentRoute.fullPath)
			data.append('previousURL', this.$router.options.history.state.back)
			data.append('description', message)
			
			const urlString = isMissingAddress ? '/bug/missingAddress/' : '/bug/'
			
			//console.log(urlString)

			const instance = axios.create()
			instance.post(this.config.url + urlString , data, {
				headers: {
					"Content-Type": "multipart/form-data",
					"Authorization": getAuthToken()
				}
			})
			.then(response => {
					
			})
			.catch(error => {	
				console.log(error)
			})

		},
		requestToBeNotified: async function(
			message = '', 
			email = this.config.user ? this.config.user.email : '') 
		{
			var data = new FormData()
			data.append('email', email)
			data.append('currentURL', this.$router.currentRoute.fullPath)
			data.append('previousURL', this.$router.options.history.state.back)
			data.append('description', message)

			const instance = axios.create()
			instance.post(this.config.url + '/notifications/' , data, {
				headers: {
					"Content-Type": "multipart/form-data",
					"Authorization": getAuthToken()
				}
			})
			.then(response => {
					
			})
			.catch(error => {	
				console.log(error)
			})

		},
		createRocketFloodApplication: async function() {

			const instance = axios.create()

			const body = {
				"address1": "952 39th Ct",
				"city": "West Palm Beach",
				"state": "FL",
				"zip": "33407",
				"phone": "555-555-5555",
				"email": "tbd@gmail.com",
				"first_name": "John",
				"last_name": "Doe",
				"foundation_type": "Crawlspace"
			}

			const locationId = '952-39th-Ct_West-Palm-Beach_FL'
				
			let url = `${this.config.url}/rocketflood/create-application/${locationId}`

			return	instance.post(url, body, {
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then(response => {
					this.rocketflood.applicationID = response.data['_id']
					this.rocketflood.location = response.data.location
					this.rocketflood.quotes = response.data.quote.filter(quote => {
						return quote.premium && quote.premium > 0
					}).sort((quoteA, quoteB) => {
						return  quoteA.premium - quoteB.premium
					}).map(quote => {
						return {
							premium: quote.premium,
							url: undefined
						}		
					})
					
					// Add Socket to Room with the Same Application ID that is used by 
					// RocketFlood
					socket.emit('applicationID', this.rocketflood.applicationID)

					return this.rocketflood 
				})
		},
		updateSurf: async function(dbCollection = undefined){
			//console.log(this.$router.currentRoute.params.id)
			if (this.$router.currentRoute.params.id != 'Location' && this.$router.currentRoute.params.id != 'notFound') {
			//console.log('update surf')
			const instance = axios.create()
			
			//console.log(this.$router.currentRoute.name)
			let url = `${this.config.endpointURL}`
			if (this.config.mapConfig.arklyRoutes.includes(this.$router.currentRoute.name)) {
				url = `${this.config.endpointURL}?dbCollection=${this.config.mongoCollections.ArklyProperties}`
			}
			
			// Set to Null, so that Spinner Activates
			this.expectedLoss = { value: undefined, max: undefined }	
			this.averageAnnualLoss = { value: undefined, max: undefined }
			this.fetchingSurfData = true

			//console.log(url)
			return	instance.get(url, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": getAuthToken(),
					//'X-Forwarded-For': this.config.userIP
				}
			})
				.then(response => {	
					this.surfData = response.data[0]
					//console.log(this.surfData)
					const bounds = this.config.user ? this.config.user.access.bounds : {}
					const coordinates = response.data[0].location.coordinates
					
					if ((!this.config.mapConfig.arklyRoutes.includes(this.$router.currentRoute.name)) && ((bounds != {}) && (( coordinates.lat < bounds['miny']) || (coordinates.lat > bounds['maxy']) || (coordinates.lng < bounds['minx']) || (coordinates.lng > bounds['maxx']))))
					{
						this.surfData.location.coordinates = undefined
						this.$router.push({name: 'economic', params: { view: this.$route.params.view, type: this.$route.params.type, id: this.config.user.address}})
						
					} else {
						this.surfData.location.coordinates = {
							'lng': coordinates.lng, 'lat': coordinates.lat
						}
					}	

					if (response.data[0].AAL){
						this.averageAnnualLoss.value = response.data[0].AAL.structure
						this.averageAnnualLoss.max = this.findMax(response.data[0].AAL.structure)
					}
					if (!this.config.mapConfig.arklyRoutes.includes(this.$router.currentRoute.name) && response.data[0].expectedLoss) {
						this.expectedLoss.value = response.data[0].expectedLoss
						this.expectedLoss.max = this.findMax(this.expectedLoss.value['structure'])
						this.expectedLoss.max = this.expectedLoss.max < 1000 ? 1000 : this.expectedLoss.max
					}
					if (response.data[0].floodZoneImpact) {
						this.floodZoneExpectedLoss.value = response.data[0].floodZoneImpact.expectedLoss.structure.mean
					}
					if (!this.location.blockgroup) {
						this.location.blockgroup = this.surfData['_id']	
					}
					if (!this.config.mapConfig.arklyRoutes.includes(this.$route.name)) {
						if (this.location.blockgroup.length > 6) {
							this.updateEquity(this.location.blockgroup)	
						}	
					}

					// Remove decimals, if present
					const zip = String(parseInt(this.surfData['location']['address']['zip']))
					if (zip.length != 5) {
						const fixedZip = '0'.repeat(5 - zip.length) + zip
						this.surfData['location']['address']['zip'] = fixedZip
					}
					this.fetchingSurfData = false
					return this.surfData
				})
				.catch(error =>  {
					if (this.$route.name == 'puertoRico') {
						var defaultID = this.defaultIdPR
					} else {
						var defaultID = this.defaultIdFL
					}

					console.log(error)
					const attemptedLocation = this.$route.params.id
					this.$router.push({
						params: { 
							'id': defaultID, 
							'type': 'property'
						}
					})
					this.fetchingSurfData = false
					this.setPropertyNotFound(true, attemptedLocation)
				})
			}
		},
		updateNFIP: async function(){
			if (this.config.mapConfig.routesWithInsurance.includes(this.$router.currentRoute.name)) {
				const blockgroup = this.blockgroup.slice(0,-1)
				const instance = axios.create()
				let url = `${this.config.url}/surf/deterministic/property/${blockgroup}/nationalFloodInsuranceProgram`
				instance.get(url, {
					headers: {
						"Content-Type": "application/json",
						"Authorization": getAuthToken(),
						//'X-Real-IP': this.config.userIP
					}
				})
					.then(response => {
						this.meanNFIP = response.data['NFIP'] //[0].totalPolicyCostStats.mean
						this.meanNFIPstr = this.abbreviateNumbers(response.data['NFIP'],2) //[0].totalPolicyCostStats.mean

					})
					.catch(error =>  {
						//console.log('error: ', error)	
					})
			}
		},
		getProperties: async function(ids) {
			let url = `${this.config.url}/surf/deterministic/property/${String(ids)}`
			const instance = axios.create()
			return instance.get(url, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": getAuthToken()
				}
			})
			.then(response => {	
				this.queryResults = response['data']
				this.fetching = false
			})
			.catch(error =>  {
				console.log('error: ', error)	
			})
		},
		compareRisk(a,b){
			return b.expectedLoss[this.queryControls['useType']][this.queryControls['dataSource']][[this.queryControls['storm'].replace(' ','')]][this.currYearToSortWith]['mean'] - a.expectedLoss[this.queryControls['useType']][this.queryControls['dataSource']][[this.queryControls['storm'].replace(' ','')]][this.currYearToSortWith]['mean']
		},
		compareRiskReverse(a,b){
			return a.expectedLoss[this.queryControls['useType']][this.queryControls['dataSource']][[this.queryControls['storm'].replace(' ','')]][this.currYearToSortWith]['mean'] - b.expectedLoss[this.queryControls['useType']][this.queryControls['dataSource']][[this.queryControls['storm'].replace(' ','')]][this.currYearToSortWith]['mean']
		},
		sortQueryData(currSort){
			var riskYears = ['2020','2050','2100']
			if (currSort.endsWith('yearBuilt') && currSort.startsWith('down')) {
				this.queryResults.sort(function(a,b) {
					return b.attributes.yearBuilt - a.attributes.yearBuilt
				});
			} else if (currSort.endsWith('yearBuilt') && currSort.startsWith('up')) {
				this.queryResults.sort(function(a,b) {
					return a.attributes.yearBuilt - b.attributes.yearBuilt
				});
			} else if (currSort.endsWith('impValue') && currSort.startsWith('down')) {
				this.queryResults.sort(function(a,b) {
					return b.attributes.tax.improvementValue - a.attributes.tax.improvementValue
				});
			} else if (currSort.endsWith('impValue') && currSort.startsWith('up')) {
				this.queryResults.sort(function(a,b) {
					return a.attributes.tax.improvementValue - b.attributes.tax.improvementValue
				});
			} 
			riskYears.forEach(year => {
				if (currSort.endsWith('risk' + year) && currSort.startsWith('down')) {
					this.currYearToSortWith = year
					this.queryResults.sort(this.compareRisk)
				} else if (currSort.endsWith('risk' + year) && currSort.startsWith('up')) {
					this.currYearToSortWith = year
					this.queryResults.sort(this.compareRiskReverse)
				}
			})
			
		},
		calcPolygonArea(vertices) {
			var total = 0;
		
			for (var i = 0, l = vertices.length; i < l; i++) {
			  var addX = vertices[i][1];
			  var addY = vertices[i == vertices.length - 1 ? 0 : i + 1][0];
			  var subX = vertices[i == vertices.length - 1 ? 0 : i + 1][1];
			  var subY = vertices[i][0];
		
			  total += (addX * addY * 0.5);
			  total -= (subX * subY * 0.5);
			}
		
			return Math.abs(total);
		},
		getPropertiesInPoly: async function(geom) {
			this.queryControls = JSON.parse(JSON.stringify(this.config.controls));
			var id = this.surfData['location']['address']['county']
			const instance = axios.create()
			const body = {"polygon":geom,"controls":this.config.controls}
			this.fetching = true
			this.noQueryResults = false
			
			//console.log(this.calcPolygonArea(geom))
			let url = `${this.config.url}/surf/deterministic/property/${id}/getPropertiesInPoly/`
			return instance.post(url, body, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": getAuthToken()
				}
			})
			.then(response => {	
				this.queryResults = response['data']
				this.fetching = false
			})
			.catch(error =>  {
				console.log('error: ', error)
				if (error.response.data.detail == 'No properties found') {
					this.noQueryResults = true
				}
				this.fetching = false	
			})
			
		},
		getHomeInsRates: async function(insType = undefined) {
			if (this.config.mapConfig.routesWithInsurance.includes(this.$router.currentRoute.name)) {
				if (insType =='clear') {
					this.homeInsInfo.rates = undefined	
					this.homeInsInfo.links = undefined
				} else {
					const instance = axios.create()
					var id = this.surfData['location']['fips']['state'] + '-'
					if (this.surfData['location']['fips']['county'].length == 1) {
						id = id + '00' + this.surfData['location']['fips']['county']
					} else if (this.surfData['location']['fips']['county'].length == 2) {
						id = id + '0' + this.surfData['location']['fips']['county']
					} else {
						id = id + this.surfData['location']['fips']['county']
					}
					let url = `${this.config.url}/surf/deterministic/property/${id}/getHomeInsuranceRates/${insType}/`
					
					return instance.get(url, {
						headers: {
							"Content-Type": "application/json",
							"Authorization": getAuthToken()
						}
					})
					.then(response => {	
						this.homeInsInfo.rates = response.data.rates	
						this.homeInsInfo.links = response.data.links
					})
					.catch(error =>  {
						console.log('error: ', error)	
					})
				}
			}
		},
		updateHouseLiftingLoan: async function(dbCollection = undefined) {
			if (this.config.mapConfig.routesWithMitigation.includes(this.$router.currentRoute.name)) {
				const instance = axios.create()
				let url = `${this.config.url}/surf/deterministic/property/${this.surfData['_id'].replace('#','%23').replace('/','forwardSlash')}/houseLiftingLoan/`
				if (this.config.mapConfig.arklyRoutes.includes(this.$router.currentRoute.name)) {
					url = url+ `?dbCollection=${this.config.mongoCollections.ArklyProperties}`
				}
				
				return instance.get(url, {
					headers: {
						"Content-Type": "application/json",
						"Authorization": getAuthToken()
					}
				})
					.then(response => {		
						this.houseLiftingLoan = response.data
					})
					.catch(error =>  {
						console.log('error: ', error)	
					})
			}
		},
		updateEL: async function(updateMax = true, dbCollection = undefined){
			const instance = axios.create()
			let url = `${this.config.endpointURL}/expectedDamage/?ffeMean=${this.FFE + this.config.controls.elevation}`
			if (this.config.mapConfig.arklyRoutes.includes(this.$router.currentRoute.name)) {
				url = `${this.config.endpointURL},dbCollection=${this.config.mongoCollections.ArklyProperties}`
			}
			return instance.get(
				url
				, {
						headers: {
							"Content-Type": "application/json",
							"Authorization": getAuthToken(),
							//'X-Real-IP': this.config.userIP
						}
				})
						.then(response => {
							this.expectedLoss.value = response.data
							if (updateMax) {
								this.expectedLoss.max = this.expectedLoss.max
							}
							return
				})
						.catch(function (error) {
							console.log(error)
							return
				})	
		},
		// function not currently being used, when this is merged with govsub, this will need to be used again
		updateAAL: async function(updateMax = true, dbCollection = undefined) {
			//console.log('update AAL')
			// Set to Null, so that Spinner Activates
			this.averageAnnualLoss = { value: undefined, max: undefined }
		
			const instance = axios.create()
			let url = `${this.config.endpointURL}/averageAnnualLoss`
			
			if (this.config.mapConfig.arklyRoutes.includes(this.$router.currentRoute.name)) {
				url = url + `?numberOfDeviations=1&dbCollection=${this.config.mongoCollections.ArklyProperties}`
				if (this.config.elevation != 0) {
					url = url + 'ffeMean=' + this.config.elevation
				}
			} else {
				if (this.config.elevation != 0) {
					url = url + '?ffeMean=' + this.config.elevation
				}
			}

			return instance.get(url, {
						headers: {
							"Content-Type": "application/json",
							"Authorization": getAuthToken(),
							//'X-Forwarded-For': this.config.userIP
						}
				})
						.then(response => {	
							this.averageAnnualLoss.value = response.data
							if (updateMax) {
								this.averageAnnualLoss.max = this.findMax(this.averageAnnualLoss.value)
							}
							return
				})
						.catch(function (error) {
							console.log('averageAnnualLoss error')
							console.log(error)
							return
				})	
		},
		updateEquity: async function(id) {
			if (this.$route.params.view == "socioeconomic") {
				const instance = axios.create()
				let url = `${this.config.url}/surf/aggregate/equity/${id}`
				
				return instance.get(url, {
							headers: {
								"Content-Type": "application/json",
								"Authorization": getAuthToken()
							}
					})
							.then(response => {	
								this.equity = response.data[0]
								this.maxPropertiesAtRisk = this.findMax(this.equity['atRisk'])
								return
					})
							.catch(function (error) {
								//console.log('averageAnnualLoss error')
								//console.log(error)
								return
					})	
			}

		},
		findMax: function(obj) { 
			if (!obj || !Object.keys(obj).length) { return [] }

			const possibleValues = Object.entries(obj).reduce((acc, [key, val]) => {
				if (typeof val == 'number') {
					acc.push(val)
				} else if (val != undefined) {
					if ('mean' in val) {
						if ('upperBound' in val) {
							acc.push(val.upperBound)
						} else if ('stdev' in val) {	
							acc.push(val.mean + 2*val.stdev)
						} else {
							acc.push(val.mean)
						}
					} else  {
						acc.push(this.findMax(val))
					}
				}
				return acc.flat()
			}, [])

			return Math.ceil((Math.max(...possibleValues).toFixed(0)))
		},
		sphericalDistance(strCoords){
			const lon1 = strCoords.split(',')[0]
			const lat1 = strCoords.split(',')[1]
			const lon2 = strCoords.split(',')[2]
			const lat2 = strCoords.split(',')[3]
			var R = 6371; // Radius of the earth in km
			var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
			var dLon = this.deg2rad(lon2-lon1); 
			var a = 
			  Math.sin(dLat/2) * Math.sin(dLat/2) +
			  Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
			  Math.sin(dLon/2) * Math.sin(dLon/2)
			  ; 
			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
			var d = R * c; // Distance in km
			return d;
		},
		deg2rad(deg) {
			return deg * (Math.PI/180)
		},
		abbreviateNumbers ( number, precision, withPrefix = true) {
			// Calculate how many digits are in the number, ie is it in the thousands,
			// millions, billions, etc?
			const abbrev = {'':1, 'K':1000, 'M':1000000, 'B':1000000000}
			const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
			const order = Math.max(0, Math.min(unrangifiedOrder, Object.keys(abbrev).length -1 ))
			const suffix = Object.keys(abbrev)[order]

			if ((number / Math.pow(10, order * 3)) == 0) {
				return 0
			} else {
				// The "precision" will determine how many digits are shown, ie "1 Million" vs "1.5 Million"
				if (withPrefix) {
					return Number((number / Math.pow(10, order * 3)).toPrecision(precision)) + suffix
				} else {
					return Number(Number((number / Math.pow(10, order * 3)).toPrecision(precision))*abbrev[suffix]).toLocaleString()
				}
			}

			/* 
			 Intl.NumberFormat('en-US', {
				notation: "compact",
				maximumFractionDigits: 0
			 }).format(26300)
			*/
		},
		// Recursive function to decode string's that have ben encoded multiple times
		tryDecodeURLComponent(str, maxInterations = 30, iterations = 0){
			if (iterations >= maxInterations) {
				return str
			} else if (typeof str === 'string' && (str.indexOf('%3D') !== -1 || str.indexOf('%25') !== -1)) {
				return this.tryDecodeURLComponent(decodeURIComponent(str), maxInterations, iterations + 1)
			}

			return decodeURIComponent(str)
		},
		setPropertyNotFound(newVisibility, newValue, lnglat) {
			//console.log(newVisibility,newValue,lnglat)
			this.propertyNotFound.isVisible = newVisibility
			if (newValue) {
				this.propertyNotFound.value = newValue 
				this.propertyNotFound.lngLat = lnglat 
			}
		},
		downloadObjectAsJson(exportObj){
			const date = new Date();
			let day = date.getDate();
			let month = date.getMonth() + 1;
			let year = date.getFullYear();
			let currentDate = `${day}-${month}-${year}`;
			var exportName = 'ArklyDataDownload' + '_' + currentDate
			var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
			var downloadAnchorNode = document.createElement('a');
			downloadAnchorNode.setAttribute("href",     dataStr);
			downloadAnchorNode.setAttribute("download", exportName + ".json");
			document.body.appendChild(downloadAnchorNode); // required for firefox
			downloadAnchorNode.click();
			downloadAnchorNode.remove();
		},
		setSurfDataFetching(value){
			this.fetchingSurfData = value
		}
	},
})
